<template>
  <div class="change-password-content">
    <comp-part title="修改密码"></comp-part>
    <el-form
    :model="ruleForm"
    status-icon
    :rules="rules"
    ref="ruleForm"
    label-width="80px"
    class="content-ruleForm"
    label-position="left">
      <el-form-item label="确认账号" required>
        <el-input v-model="doctorInfo.username" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="原密码" prop="oldPass" required>
        <el-input placeholder="请输入密码" show-password v-model="ruleForm.oldPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPass" required>
        <el-input placeholder="请输入密码" show-password v-model="ruleForm.newPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass" required>
        <el-input placeholder="请输入密码" show-password v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="verifyCode">
        <el-col :span="16">
          <el-input class="form-ipt-verification-code" placeholder="请输入验证码" v-model="ruleForm.verifyCode"></el-input>
        </el-col>
        <el-col :span="6">
          <el-button class="form-btn-verification-code" :disabled="disabledVerCodeBtn" @click="handleVerificationCode(doctorInfo.phone)">{{countDownText}}</el-button>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button style="width:100%" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { changePassword } from '@/service/module/doctor'
import { getDoctorInfo } from '@/service/module/common'
import CompPart from '@/components/Part'
import md5 from 'js-md5'
import { getDoctorSMS } from '@/service/module/hospitalManage'
export default {
  components: {
    CompPart
  },
  data () {
    var pattern = /(?!.*\s)(?!^[0-9]+$)(?!^[^A-Za-z0-9]+$)(?!^[^A-Za-z.*\s]+$)(?!^[^0-9.*\s]+$)^.{8,20}$/
    var validatePassOld = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!pattern.test(value)) {
        callback(new Error('密码格式有误，请输入8-20位英文、数字、符号（.和_）'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePassNew = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!pattern.test(value)) {
        callback(new Error('密码格式有误，请输入8-20位英文、数字、符号（.和_）'))
      } else if (value === this.ruleForm.oldPass) {
        callback(new Error('新旧密码不能相同'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (!pattern.test(value)) {
        callback(new Error('密码格式有误，请输入8-20位英文、数字、符号（.和_）'))
      } else if (value !== this.ruleForm.newPass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      doctorInfo: {},
      ruleForm: {
        oldPass: '',
        newPass: '',
        checkPass: '',
        account: '',
        verifyCode: ''
      },
      rules: {
        oldPass: [
          { validator: validatePassOld, trigger: 'blur' }
        ],
        newPass: [
          { validator: validatePassNew, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      countDownText: '获取验证码',
      disabledVerCodeBtn: false
    }
  },
  mounted () {
    getDoctorInfo().then(res => {
      if (res) {
        this.doctorInfo = res.data
      }
    })
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          changePassword({
            oldPassword: md5(this.ruleForm.oldPass),
            newPassword: md5(this.ruleForm.newPass),
            verifyCode: this.ruleForm.verifyCode
          }).then(res => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.$router.push({
                path: '/login'
              })
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleVerificationCode (phone) {
      this.disabledVerCodeBtn = true
      getDoctorSMS({
        phone: phone
      }).then(res => {
        if (res.code === 200) {
          let val = 60
          const timer = setInterval(() => {
            if (val === 0) {
              this.countDownText = '获取验证码'
              this.disabledVerCodeBtn = false
              clearTimeout(timer)
            } else {
              val--
              this.countDownText = val + 's'
            }
          }, 1000)
        } else {
          this.disabledVerCodeBtn = false
        }
      })
    }
  }
}
</script>
<style scoped>
.change-password-content {
  margin:  0 20px;
}
.content-ruleForm {
  width: 400px;
  margin-top: 24px;
}

.form-ipt-verification-code {
  width: 200px;
}
</style>
